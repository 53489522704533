<template>
  <v-container fluid>
    <v-card elevation="4" class="mt-0">
      <v-tabs
        v-model="tab"
        @change="tabChanged(tab)"
        background-color="#5E2FBE"
        dark
        disabled
      >
        <v-tab :disabled="loading" v-for="item in items" :key="item.tab">
          {{ item.tab }}
        </v-tab>
        <v-spacer></v-spacer>
      
        <v-btn
          outlined
          small
          class="my-auto mr-2 rounded-0"
          @click="() => showImportProcurementsModal()"

        >
           {{
              this.$t("import_csv")
            }}
        </v-btn>
        <import-procurements-modal></import-procurements-modal>
        <v-btn
          outlined
          small
          class="ml-auto my-auto mr-2 rounded-0"
          v-if="$admin.can('supplyOrder-export')"
          @click="getCSV(filters, true)"
          :loading="is_loading_detail_csv"
          :disabled="is_loading_detail_csv"
        >
          <v-icon color="white" class="mr-2">
            mdi-arrow-down-bold-circle-outline
          </v-icon>
          {{ $t("export_detail_csv") }}
        </v-btn>

        <v-btn
          outlined
          small
          class="my-auto mr-2 rounded-0"
          v-if="$admin.can('supplyOrder-export')"
          @click="getCSV(filters)"
          :loading="is_loading_csv"
          :disabled="is_loading_csv"
        >
          <v-icon color="white" class="mr-2">
            mdi-arrow-down-bold-circle-outline
          </v-icon>
          {{ $t("export_result_csv") }}
        </v-btn>
        <div class="white my-auto mr-2">
          <v-btn
            outlined
            small
            class="primary--text my-auto rounded-0"
            v-if="$admin.can('supplyOrder-create')"
            @click="addItem()"
          >
            {{ $t("supplyOrders.add_new_supply_order") }}
          </v-btn>
        </div>
      </v-tabs>

      <v-tabs-items v-model="tab">
        <v-tab-item v-for="item in items" :key="item.tab">
          <draft-tab
            :filters="filters"
            v-if="item.content == 'draft'"
            :isActive="items[tab]?.content == 'draft'"
          ></draft-tab>
          <submitted-tab
            v-if="item.content == 'submitted'"
            :filters="filters"
            :isActive="items[tab]?.content == 'submitted'"
          >
          </submitted-tab>
          <confirmed-tab
            v-if="item.content == 'confirmed'"
            :filters="filters"
            :isActive="items[tab]?.content == 'confirmed'"
          >
          </confirmed-tab>
          <history-tab
            v-if="item.content == 'history'"
            :filters="filters"
            :isActive="items[tab]?.content == 'history'"
          >
          </history-tab>
        </v-tab-item>
      </v-tabs-items>
    </v-card>
  </v-container>
</template>

<script>



export default {
  components: {
    DraftTab: () => import("./tabs/DraftTab.vue"),
    SubmittedTab: () => import("./tabs/SubmittedTab.vue"),
    ConfirmedTab: () => import("./tabs/ConfirmedTab.vue"),
    HistoryTab: () => import("./tabs/HistoryTab.vue"),
    ImportProcurementsModal: () => import("./modals/ImportProcurementsModal.vue"),

  },

  computed: {
    filters: {
      get() {
        return this.$store.state.supplyOrders.filters;
      },
      set(value) {
        this.$store.commit("supplyOrders/SET_FILTERS", value);
      },
    },
    selected: {
      get() {
        return this.$store.state.supplyOrders.selected;
      },
      set(value) {
        this.$store.commit("supplyOrders/SET_SELECTED", value);
      },
    },
  },
  methods: {
    async tabChanged(index) {
      try {
        this.$store.commit("supplyOrders/SET_SELECTED", index);
        this.$store.commit("supplyOrders/CLEAN_LIST");
        this.$store.dispatch("supplyOrders/setParams", {
          supply_order_status: this.items[index].status,
        });

        this.filters.supply_order_status = this?.items[index]?.status;
        await this.$store.dispatch("supplyOrders/list", this.filters, {
          supply_order_status: this.items[index].status,
        });
        this.$store.commit("SET_PAGE_SUBTITLE", "");
        this.$store.commit("SET_SUBTITLE_ID", "");
      } catch (error) {
        this.$store.dispatch("alerts/error", error.response?.data?.message);
      }
    },
    addItem() {
      this.$router.push({ name: "supplyOrders.new" });
    },
    async getCSV(filters, details = false) {
      details
        ? (this.is_loading_detail_csv = true)
        : (this.is_loading_csv = true);
      filters.details = details;

      await this.$store
        .dispatch("supplyOrders/downloadCSV", filters)
        .then((data) => {
          const url = window.URL.createObjectURL(new Blob([data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "supply-order.csv");
          document.body.appendChild(link);
          // window.open(link);
          link.click();
          this.is_loading_csv = false;
          details
            ? (this.is_loading_detail_csv = false)
            : (this.is_loading_csv = false);
        })
        .catch((error) => {
          details
            ? (this.is_loading_detail_csv = false)
            : (this.is_loading_csv = false);
          this.$store.dispatch("alerts/error", error.response?.statusText);
          this.$store.dispatch("alerts/error", error.response?.data?.message);
        });
    },
    showImportProcurementsModal() {
      this.$store.commit("supplyOrders/SET_IMPORT_PROCUREMENTS_MODAL_VISIBLE", true);
    },
  },

  async created() {
    this.filters.supply_order_status = this?.items[this.tab]?.status ?? "sent";
    this.tab = this.selected;
    this.$store.commit("SET_PAGE_SUBTITLE", "");
    this.$store.commit("SET_SUBTITLE_ID", "");
  },

  data() {
    return {
      is_loading_csv: false,
      is_loading_detail_csv: false,
      tab: 1,
      loading: false,
      items: [
        {
          tab: this.$t("draft"),
          content: "draft",
          status: "draft",
          id: 0,
        },
        {
          tab: this.$t("submitted"),
          content: "submitted",
          status: "sent",
          id: 1,
        },
        {
          tab: this.$t("confirmed"),
          content: "confirmed",
          status: "confirmed",
          id: 2,
        },
        {
          tab: this.$t("history"),
          content: "history",
          status: "history",
          id: 3,
        },
      ],
    };
  },
};
</script>
